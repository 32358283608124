import React from 'react';
import PaypalExpressBtn from 'gatsby-paypal-button';
import { StaticImage } from "gatsby-plugin-image";

import {
	Container,
	Row,
	Col,
	FormGroup,
	Label,
	Input,
	Form,
	Button,
	Link
  } from "reactstrap";

export default class PaypalButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		  paymentAmount: 73.04,
		  charge: 70.00,
		  fee: 3.04,
		  selectedOption: "option1",
		  isPaypalDisabled: true,
		  email: '',
		  address: '',
		  validate: {
			emailState: '',
			addressState: '',
		  },
		};
	}

	handleOptionChange = changeEvent => {
		let tmpCharge, tmpFee = null;
		switch(changeEvent.target.value) {
			case 'option2':
				tmpCharge=148.40;
				tmpFee=5.87;
				break;
			case 'option3':
				tmpCharge=236.21;
				tmpFee=9.05;
				break;
			case 'option4':
				tmpCharge=313.47;
				tmpFee=11.84;
				break;
			default:
				tmpCharge=70.00;
				tmpFee=3.04;
		}
		this.setState({
		  selectedOption: changeEvent.target.value,
		  charge: tmpCharge,
		  fee: tmpFee,
		  paymentAmount: parseFloat( (tmpCharge + tmpFee).toFixed(2) )
		});
	};

	handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };

	validateForm(e) {
		if (this.state.validate.emailState === 'has-success' && this.state.validate.addressState === 'has-success') {
			this.state.isPaypalDisabled = false;	
		} else {
			this.state.isPaypalDisabled = true;
		}
	}

	validateEmail(e) {
		const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const forbiddenRegex = /~|!|\*|\(|\)|\'/;
	  
		const { validate } = this.state;
	  
		if ( emailRegex.test(e.target.value) && !forbiddenRegex.test(e.target.value) ) {
		  validate.emailState = 'has-success';
		} else {
		  validate.emailState = 'has-danger';
		}
	  
		this.setState({ validate });
	}

	validateAddress(e) {
		const addressRegex = /^\d{1,}(\s{1}\w{1,})(\s{1}?\w{1,}?)+$/;
		const forbiddenRegex = /~|!|\*|\(|\)|\'/;
	  
		const { validate } = this.state;
	  
		if ( addressRegex.test(e.target.value) && !forbiddenRegex.test(e.target.value) ) {
		  validate.addressState = 'has-success';
		} else {
		  validate.addressState = 'has-danger';
		}
	  
		this.setState({ validate });
	}

    render() {

		
		const onSuccess = (payment) => {
			// 1, 2, and ... Poof! You made it, everything's fine and dandy!
            		console.log("Payment successful!", payment);
            		// You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data

					this.props.handleAlertVis('success');
					window.setTimeout(()=>{
						this.props.handleAlertVis('success')
					},5000)
		}

		const onCancel = (data) => {
			// The user pressed "cancel" or closed the PayPal popup
			console.log('Payment cancelled!', data);
			// You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data

			this.props.handleAlertVis('cancel');
			window.setTimeout(()=>{
				this.props.handleAlertVis('cancel')
			},5000)
		}

		const onError = (err) => {
			// The main Paypal script could not be loaded or something blocked the script from loading
			console.log("Error!", err);
			// Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
			// => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
			
			this.props.handleAlertVis('error');
			window.setTimeout(()=>{
				this.props.handleAlertVis('error')
			},5000)
		}

		//let env = `${process.env.GATSBY_PAYPAL_ENVIRONMENT}`; // you can set this string to 'production'
		//let env = 'sandbox';
		let env = 'production';
		let currency = 'USD'; // you can set this string from your props or state  
		//let total = 73.04;  // this is the total amount (based on currency) to charge
		// Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

		const client = {
			//sandbox:    `${process.env.GATSBY_PAYPAL_DEV_CLIENT_ID}`,
			//production: `${process.env.GATSBY_PAYPAL_PROD_CLIENT_ID}`,

			sandbox:	'AaQ-GWS_vLLaxXFPZ08VOHxhQk65lVk3z6jDDcHgWUNcwIXWuP1aqoDWZZSABsuWOBq3cvO8qKj1paLP',
			production:	'ARJfRcW5c3o1H5WALE3Ff8kI9kvDmF8kcq8UXF7VM2bjXJTQYlLstWE8pnTYJQDptiRwxsfHiWnpKtU1',
		}
		// In order to get production's app-ID, you will have to send your app to Paypal for approval first
		// For your sandbox Client-ID (after logging into your developer account, please locate the "REST API apps" section, click "Create App" unless you have already done so):
		//   => https://developer.paypal.com/docs/classic/lifecycle/sb_credentials/
		// Note: IGNORE the Sandbox test AppID - this is ONLY for Adaptive APIs, NOT REST APIs)
		// For production app-ID:
		//   => https://developer.paypal.com/docs/classic/lifecycle/goingLive/

		// NB. You can also have many Paypal express checkout buttons on page, just pass in the correct amount and they will work!

        // Style the button
        const style = {
            size: 'small',
            color: 'blue',
            shape: 'pill',
            label: 'checkout',
            tagline: 'false'
        }

        return (
			<>
				<Container>
					<Row>
						<Col>
							{/* <br /> */}
							<Form id="paypal-form">
								<FormGroup className="mb-1">
									<FormGroup className="mb-1">
									<Input
										name="radio1"
										type="radio"
										checked={this.state.selectedOption === "option1"}
										value="option1"
										onChange={this.handleOptionChange}
									/>
									{' '}
									<Label>
										I need to pay 2024 dues
									</Label>
									</FormGroup>
									<FormGroup className="mb-1">
									<Input
										name="radio1"
										type="radio"
										checked={this.state.selectedOption === "option2"}
										value="option2"
										onChange={this.handleOptionChange}
									/>
									{' '}
									<Label>
										I need to pay past-due 2023 dues, along with current 2024 dues
									</Label>
									</FormGroup>
									<FormGroup className="mb-1">
									<Input
										name="radio1"
										type="radio"
										checked={this.state.selectedOption === "option3"}
										value="option3"
										onChange={this.handleOptionChange}
									/>
									{' '}
									<Label>
										I need to pay past-due 2022 and 2023 dues, along with curent 2024 dues
									</Label>
									</FormGroup>
									<FormGroup className="mb-1">
									<Input
										name="radio1"
										type="radio"
										checked={this.state.selectedOption === "option4"}
										value="option4"
										onChange={this.handleOptionChange}
									/>
									{' '}
									<Label>
										I need to pay past-due 2020/2021, 2022, and 2023 dues, along with current 2024 dues
									</Label>
									</FormGroup>
								</FormGroup>
								<FormGroup>
									<Label for="email">Email</Label>
									<Input 
										type="email"
										id="email"
										name="email"
										// placeholder="jane.doe@website.com"
										value={this.state.email}
										valid={ this.state.validate.emailState === 'has-success' }
  										invalid={ this.state.validate.emailState === 'has-danger' }
										onChange={ (e) => {
															this.validateEmail(e);
															this.validateForm(e);
															this.handleInputChange(e);
															} }
										// onChange={this.handleInputChange}
									/>
                    			</FormGroup>
								<FormGroup>
									<Label for="address">Address</Label>
									<Input
										type="address"
										id="address"
										name="address"
										// placeholder="123 Main Street"
										value={this.state.address}
										valid={ this.state.validate.addressState === 'has-success' }
  										invalid={ this.state.validate.addressState === 'has-danger' }
										onChange={ (e) => {
															this.validateAddress(e);
															this.validateForm(e);
															this.handleInputChange(e);
															} }
										// onChange={this.handleInputChange}
									/>
                    			</FormGroup>
							</Form>
						</Col>
					</Row>
				</Container>
			
				<Container>
					<Row>
						<Col>
							<div className={`${this.state.isPaypalDisabled ? "paypal-disable" : ""}`}>
								<PaypalExpressBtn style={style} env={env} client={client} currency={currency} total={this.state.paymentAmount} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />
							</div>
						</Col>
					</Row>
					{/* <Row >
						<Col md="5">
							<p className="text-center"><u>or</u></p>
							<br />
						</Col>
					</Row> */}
					<Row className="mt-2">
						<Col>
						<Button
							disabled = {this.state.isPaypalDisabled}
							href={"https://venmo.com/EHRHOA?txn=pay&note=HOA%20dues%20payment%20-%20email:" + encodeURIComponent(this.state.email) + "%20-%20address:" + encodeURIComponent(this.state.address) + "&amount=" + this.state.paymentAmount}
							className="btn-round venmo-button"
							size="sm"
							role="button"
							target="_blank"
							rel="noopener noreferrer">
								<StaticImage
									src="../images/Venmo_Logo_White.png"
									alt="Venmo Logo"
									className="img-responsive venmo-logo"
									placeholder="blurred"
								/>
							</Button>
						</Col>
					</Row>
				</Container>
				
				{/* <p>charge is: {this.state.charge} and fee is: {this.state.fee} and total is: {this.state.paymentAmount}</p> */}
				<p>*Includes convience fee of ${this.state.fee.toFixed(2)}</p>

			</>
        );
    }
}