import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout"

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

import OtherPageHeader from "../components/otherPageHeader.js";
import PaypalButton from "../components/paypalButton";


const DuesPage = () => {
  const [alert, setAlert] = React.useState(true);
  const [payAlertSuccess, setPayAlertSuccess] = React.useState(false);
  const [payAlertCancel, setPayAlertCancel] = React.useState(false);
  const [payAlertError, setPayAlertError] = React.useState(false);
  const handleAlertVis = (result) => {
    switch (result) {
      case 'success':
        setPayAlertSuccess(!payAlertSuccess);
        break;
      case 'cancel':
        setPayAlertCancel(!payAlertCancel);
        break;
      default:
        setPayAlertError(!payAlertError);
    }
  }

  return (
    <>
      <Layout pageTitle="Dues">
        <OtherPageHeader />
        {/* <Alert className="alert-with-icon home-alert" color="info" isOpen={alert}>
          <Container>
            <div className="alert-wrapper">
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => setAlert(false)}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <div className="message">
                <i className="nc-icon nc-bell-55" /> <b>Stay tuned!</b>  Online dues payments coming soon!
              </div>
            </div>
          </Container>
        </Alert> */}
        <div className="main">
          <div className="section">
            <Container>
              <Row className="align-items-center">
                <Col md="6">
                  <h2 className="text-primary">Mandatory Dues</h2>
                  {/* <Alert className="text-center" color="danger">
                    If you have not paid your 2020-2021 dues please contact us 
                    <Button tag={Link} to="/contact" className="btn-link pl-1 alert-link"size="sm">
                      HERE
                    </Button>
                  </Alert> */}
                  <h5 className="description">All homeowners within Elkhorn Highland Ridge are contractually required to pay annual dues pursuant to the covenants filed September 23rd, 2004 as MISC#2004125770.</h5>
                  <ul>
                    <li><p>Dues for the 2020-2021 year covered from August 2020 through December 2021. They were $55.</p></li>
                    <li><p>2022 and 2023 dues ran concurrently with the calendar year, January through December. The amount was set at $70.00 and was approved by the Board of Directors.</p></li>
                    <li><p>2024 dues will remain at $70 and are due on January 1, 2024.</p></li>
                  </ul>

                  <Row className="justify-content-center">
                    <Col lg="9">
                      <h3 className="text-primary">Pay Online</h3>
                      <p class="text-danger">***NOTE: All amounts beyond the current year include accrued interest***</p>
                      <PaypalButton handleAlertVis={handleAlertVis.bind(this)} />

                      <Alert color="success" isOpen={payAlertSuccess} >
                        Payment Successful!
                      </Alert>
                      <Alert color="warning" isOpen={payAlertCancel} >
                        Payment Cancelled.
                      </Alert>
                      <Alert color="danger" isOpen={payAlertError} >
                        Payment Error! Please try again.
                      </Alert>

                      <h3 className="text-primary">Send Dues Payments</h3>
                      <p>Please make checks payable to:</p>
                      <Card className="no-transition">
                        {/* <CardHeader>Treasurer</CardHeader> */}
                          <CardBody>
                            <CardTitle>Elkhorn Highland Ridge HOA</CardTitle>
                            <CardText>
                              P.O. Box 216<br />
                              Elkhorn, NE 68022
                            </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  {/* <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={require("images/landscape.jpg").default}
                  /> */}
                  
                  <h3 className="text-primary">What do my dues pay for?</h3>
                  <p><i className="nc-icon nc-check-2" /> Entrance and island landscaping</p>
                  <p><i className="nc-icon nc-check-2" /> Entrance and island power, water, and sewer</p>
                  <p><i className="nc-icon nc-check-2" /> Legal fees</p>
                  <p><i className="nc-icon nc-check-2" /> Insurance</p>
                  <p><i className="nc-icon nc-check-2" /> Special projects</p>
                  <p><i className="nc-icon nc-check-2" /> Neighborhood events</p>
                  <br/>
                  <StaticImage
                    src="../images/landscape.jpg"
                    alt="Elkhorn Highland Ridge Neighborhood Landscaping"
                    className="img-rounded img-responsive"
                    placeholder="blurred"
                  />
                </Col>
                {/* <Col md="6">
                  <h3 className="text-primary">What do my dues pay for?</h3>
                  <p><i className="nc-icon nc-check-2" /> Legal fees</p>
                  <p><i className="nc-icon nc-check-2" /> Insurance</p>
                  <p><i className="nc-icon nc-check-2" /> Landscaping and lawn care</p>
                  <p><i className="nc-icon nc-check-2" /> Power, water, and sewer</p>
                  <p><i className="nc-icon nc-check-2" /> Special projects</p>
                  <p><i className="nc-icon nc-check-2" /> Neighborhood events</p>
                </Col> */}
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default DuesPage
